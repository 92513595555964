.pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  margin-top: 4px;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.front {
  display: block;
  position: relative;
  padding: 12px 42px;
  border-radius: 12px;
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  color: white;
  font-size: 1.25rem;
  font-family: var(--font-rubik), "Rubik", sans-serif;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 500;
}

.pushable:hover {
  filter: brightness(110%);
}

.pushable:hover .front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.pushable:active .front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.pushable:hover .shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.pushable:active .shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.pushable:focus:not(:focus-visible) {
  outline: none;
}

.pushable:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pushable:disabled:active .front {
  transform: translateY(-6px);
}

.front.red {
  background: hsl(345deg 100% 47%);
}

.edge.red {
  background: linear-gradient(
    to left,
    hsl(340deg 100% 16%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
}

.front.gray {
  background: hsl(217, 19%, 27%);
}

.edge.gray {
  background: linear-gradient(
    to left,
    hsl(214, 19%, 8%) 0%,
    hsl(214, 19%, 18%) 8%,
    hsl(214, 19%, 18%) 92%,
    hsl(214, 19%, 8%) 100%
  );
}

.front.green {
  background: hsl(142, 76%, 36%);
}

.edge.green {
  background: linear-gradient(
    to left,
    hsl(138, 61%, 12%) 0%,
    hsl(138, 64%, 24%) 8%,
    hsl(138, 64%, 24%) 92%,
    hsl(138, 61%, 12%) 100%
  );
}

.shadow.small {
  border-radius: 6px;
}

.edge.small {
  border-radius: 6px;
}

.front.small {
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 0.875rem;
}
